.carousel {
  .glide__slides {
    @apply m-auto;
    @apply list-none;
  }

  &--references {
    .glide__slide {
      @apply flex items-center justify-center w-56 h-36 border border-grey-400 rounded-md;

      svg,
      object {
        @apply absolute;
      }
    }

    .glide__track {
      @apply overflow-visible relative;

      &::before {
        @apply top-0 bottom-0 right-[100%] -left-[100%] bg-blue-900 absolute z-10;
        content: "";
      }
    }

    .glide__arrows {
      @apply hidden absolute justify-center items-center w-[50px] h-[50px] -mt-6 shadow-slider-arrow rounded-full bg-green-300 md:flex;

      .glide__arrow {
        @apply absolute w-6 flex justify-center top-0 bottom-0 float-right items-center;

        &--left {
          @apply rotate-180 left-0;
        }

        &--right {
          @apply right-0;
        }
      }
    }

    .glide__bullets {
      @apply bg-grey-300/50 rounded-full mt-8 lg:mt-12;
    }

    .glide__bullet {
      @apply block w-full h-0.5 bg-green-300;
          transition: background-color ease;

      &:first-child {
        @apply rounded-l-full;
      }

      &--active {
        @apply rounded-r-full;

        ~ .glide__bullet {
          @apply bg-grey-400;
        }
      }
    }
  }

  &--quotes {
    .glide__track {
      @apply mb-9 overflow-visible;

      .glide__slide:not(.glide__slide--active) {
        .quote {
          @apply bg-transparent shadow-none text-grey-300;

          &::after {
            @apply content-none;
          }
        }
      }
    }

    .glide__arrows {
      @apply flex w-full justify-center;

      .glide__arrow {
        @apply bg-white relative w-12 h-12 rounded-full flex items-center justify-center;

        &:hover,
        &:focus {
          @apply bg-grey-100;
        }

        svg {
          @apply absolute;
        }

        &--left {
          svg {
            @apply rotate-180;
          }
        }

        &--right {
          @apply ml-3;
        }
      }
    }
  }
}
