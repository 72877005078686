*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Greycliff CF, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  --tw-text-opacity: 1;
  color: rgb(127 143 164 / var(--tw-text-opacity));
  line-height: 2;
}

h1, h2, h3, h4, h5, h6 {
  --tw-text-opacity: 1;
  color: rgb(25 30 60 / var(--tw-text-opacity));
  font-family: Greycliff CF, sans-serif;
  font-weight: 800;
  line-height: 1.25;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

h1 {
  letter-spacing: -.4px;
  font-size: clamp(2.5rem, 2.1479rem + 1.5023vw, 3.5rem);
  line-height: 2.75rem;
}

@media (width >= 1024px) {
  h1 {
    line-height: 1.1;
  }
}

h2 {
  letter-spacing: -1px;
  font-size: clamp(2.25rem, 2.0299rem + .939vw, 2.875rem);
  line-height: 2.75rem;
}

@media (width >= 1024px) {
  h2 {
    line-height: 1.1;
  }
}

.content h2 + :not([hidden]) {
  --content-spacing-top: .75rem;
}

@media (width >= 768px) {
  .content h2 + :not([hidden]) {
    --content-spacing-top: 1.25rem;
  }
}

h3 {
  letter-spacing: -1px;
  font-size: clamp(1.875rem, 1.743rem + .5634vw, 2.25rem);
}

@media (width >= 1024px) {
  h3 {
    line-height: 1.1;
  }
}

.content h3 + :not([hidden]) {
  --content-spacing-top: .75rem;
}

h4 {
  letter-spacing: -1px;
  font-size: clamp(1.125rem, 1.081rem + .1878vw, 1.25rem);
}

@media (width >= 1024px) {
  h4 {
    line-height: 1.1;
  }
}

.content h4 + :not([hidden]) {
  --content-spacing-top: .75rem;
}

h5 {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

@media (width >= 1024px) {
  h5 {
    line-height: 1.1;
  }
}

.content h5 + :not([hidden]) {
  --content-spacing-top: .5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.content h6 + :not([hidden]) {
  --content-spacing-top: .5rem;
}

dl > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

dt {
  font-weight: 600;
}

dd {
  margin-left: .5rem;
}

strong {
  font-weight: 600;
}

code {
  background-color: #e7f6fde6;
  border-radius: .375rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-block;
}

a:where(:not(.button, .tag)), .fake-link:where(:not(.button, .tag)) {
  --tw-text-opacity: 1;
  color: rgb(40 204 139 / var(--tw-text-opacity));
  align-items: center;
  gap: .25rem;
  text-decoration-line: underline;
  display: inline-flex;
}

a:where(:not(.button, .tag)):hover, .fake-link:where(:not(.button, .tag)):hover {
  --tw-text-opacity: 1;
  color: rgb(61 83 114 / var(--tw-text-opacity));
}

svg {
  text-decoration-line: none;
}

figcaption {
  opacity: .7;
  padding-left: 1rem;
  padding-right: 1rem;
}

[x-cloak] {
  display: none !important;
}

@font-face {
  font-display: swap;
  font-family: Greycliff CF;
  font-style: normal;
  font-weight: normal;
  src: url("greycliffcf-regular.2e3cc4a1.woff2") format("woff2"), url("greycliffcf-regular.11cceb12.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Greycliff CF;
  font-style: italic;
  font-weight: normal;
  src: url("greycliffcf-regularoblique.e25b11e1.woff2") format("woff2"), url("greycliffcf-regularoblique.88aab94e.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Greycliff CF;
  font-style: normal;
  font-weight: 800;
  src: url("greycliffcf-extrabold.9a1cb670.woff2") format("woff2"), url("greycliffcf-extrabold.51b842b6.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Greycliff CF;
  font-style: italic;
  font-weight: 800;
  src: url("greycliffcf-extraboldoblique.7093f9f9.woff2") format("woff2"), url("greycliffcf-extraboldoblique.98a34700.woff") format("woff");
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.button {
  border-radius: 1.5rem;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1;
  text-decoration-line: none;
  display: inline-flex;
}

.button:hover .button-arrow {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-arrow {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-left: .5rem;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(40 204 139 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-primary:hover, .button-primary .hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 20px 0 #00000026;
  --tw-shadow-colored: 0 10px 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-primary:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(206 208 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(206 208 218 / var(--tw-bg-opacity));
}

.button-outline-primary {
  --tw-text-opacity: 1;
  color: rgb(40 204 139 / var(--tw-text-opacity));
  border-width: 2px;
  padding: .625rem 1.5rem;
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-outline-primary:hover, .button-outline-primary .hover {
  --tw-border-opacity: 1;
  border-color: rgb(40 204 139 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(40 204 139 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 20px 0 #00000026;
  --tw-shadow-colored: 0 10px 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-outline-primary:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(206 208 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(206 208 218 / var(--tw-bg-opacity));
}

.button-outline-primary .button-logo {
  fill: #28cc8b;
}

.button-outline-primary:hover .button-logo {
  fill: #fff;
}

.button-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(25 30 60 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  align-items: center;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .25rem;
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.button-secondary:hover, .button-secondary .hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 20px 0 #00000026;
  --tw-shadow-colored: 0 10px 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-secondary:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(206 208 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(206 208 218 / var(--tw-bg-opacity));
}

.button-alternative {
  border-radius: .375rem;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.card {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
  top: 0;
  bottom: 0;
}

.card svg {
  display: inline-block;
}

.card__link svg {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-left: .5rem;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.card:hover {
  --tw-shadow: 0 20px 40px 0 #0003;
  --tw-shadow-colored: 0 20px 40px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  top: -.625rem;
  bottom: .625rem;
}

.card:hover .card__link svg {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.card--bg-visual:after {
  content: "";
  z-index: -10;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 105 / var(--tw-bg-opacity));
  border-radius: .5rem;
  width: 158px;
  height: 277px;
  display: none;
  position: absolute;
  top: -.625rem;
  right: 38px;
}

@media (width >= 1024px) {
  .card--bg-visual:after {
    display: block;
  }
}

.carousel .glide__slides {
  margin: auto;
  list-style-type: none;
}

.carousel--references .glide__slide {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(127 143 164 / var(--tw-border-opacity));
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 9rem;
  display: flex;
}

.carousel--references .glide__slide svg, .carousel--references .glide__slide object {
  position: absolute;
}

.carousel--references .glide__track {
  position: relative;
  overflow: visible;
}

.carousel--references .glide__track:before {
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(25 30 60 / var(--tw-bg-opacity));
  content: "";
  position: absolute;
  inset: 0 100% 0 -100%;
}

.carousel--references .glide__arrows {
  --tw-bg-opacity: 1;
  background-color: rgb(40 204 139 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 20px 0 #0003;
  --tw-shadow-colored: 0 0 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-top: -1.5rem;
  display: none;
  position: absolute;
}

@media (width >= 768px) {
  .carousel--references .glide__arrows {
    display: flex;
  }
}

.carousel--references .glide__arrows .glide__arrow {
  float: right;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel--references .glide__arrows .glide__arrow--left {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
}

.carousel--references .glide__arrows .glide__arrow--right {
  right: 0;
}

.carousel--references .glide__bullets {
  background-color: #ced0da80;
  border-radius: 9999px;
  margin-top: 2rem;
}

@media (width >= 1024px) {
  .carousel--references .glide__bullets {
    margin-top: 3rem;
  }
}

.carousel--references .glide__bullet {
  --tw-bg-opacity: 1;
  background-color: rgb(40 204 139 / var(--tw-bg-opacity));
  width: 100%;
  height: .125rem;
  transition: background-color;
  display: block;
}

.carousel--references .glide__bullet:first-child {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.carousel--references .glide__bullet--active {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.carousel--references .glide__bullet--active ~ .glide__bullet {
  --tw-bg-opacity: 1;
  background-color: rgb(127 143 164 / var(--tw-bg-opacity));
}

.carousel--quotes .glide__track {
  margin-bottom: 2.25rem;
  overflow: visible;
}

.carousel--quotes .glide__track .glide__slide:not(.glide__slide--active) .quote {
  --tw-text-opacity: 1;
  color: rgb(206 208 218 / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
}

.carousel--quotes .glide__track .glide__slide:not(.glide__slide--active) .quote:after {
  --tw-content: none;
  content: var(--tw-content);
}

.carousel--quotes .glide__arrows {
  justify-content: center;
  width: 100%;
  display: flex;
}

.carousel--quotes .glide__arrows .glide__arrow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  display: flex;
  position: relative;
}

.carousel--quotes .glide__arrows .glide__arrow:hover, .carousel--quotes .glide__arrows .glide__arrow:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 248 / var(--tw-bg-opacity));
}

.carousel--quotes .glide__arrows .glide__arrow svg {
  position: absolute;
}

.carousel--quotes .glide__arrows .glide__arrow--left svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.carousel--quotes .glide__arrows .glide__arrow--right {
  margin-left: .75rem;
}

.hero-image {
  margin-top: 2.75rem;
  position: relative;
}

@media (width >= 1024px) {
  .hero-image {
    margin-top: 0;
  }
}

.hero-image:after {
  content: "";
  z-index: -10;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 105 / var(--tw-bg-opacity));
  border-radius: .5rem;
  width: 95px;
  height: 166px;
  position: absolute;
  bottom: -9px;
  left: 30px;
}

@media (width >= 1024px) {
  .hero-image:after {
    width: 158px;
    height: 277px;
    bottom: -20px;
    left: 37px;
  }
}

.hero-image img {
  --tw-shadow: 0 12px 24px 0 #0000001a;
  --tw-shadow-colored: 0 12px 24px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
}

.hero-image__document {
  --tw-shadow: 0 14px 29px 0 #0000001a;
  --tw-shadow-colored: 0 14px 29px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 40vw;
  position: absolute;
  top: 26px;
  left: -.625rem;
}

@media (width >= 1024px) {
  .hero-image__document {
    max-width: 252px;
  }
}

@media (width >= 1280px) {
  .hero-image__document {
    top: 52px;
    left: -50px;
  }
}

.hero-image__cracks {
  --tw-shadow: 0 17px 35px 0 #0000001a;
  --tw-shadow-colored: 0 17px 35px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 40vw;
  position: absolute;
  bottom: 43px;
  right: -.625rem;
}

@media (width >= 1024px) {
  .hero-image__cracks {
    max-width: 216px;
  }
}

@media (width >= 1280px) {
  .hero-image__cracks {
    bottom: 72px;
    right: -6rem;
  }
}

.usp {
  padding-left: 72px;
  position: relative;
}

.usp:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M4.792 7.877 1.869 5.058.625 6.266l4.167 4.047 8.583-8.335L12.131.75z'/%3E%3C/svg%3E");
  z-index: -10;
  border-width: 28px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-top: .25rem;
  padding: 17px;
  display: flex;
  position: absolute;
  top: -1.75rem;
  left: 0;
}

.usp--blue:before {
  --tw-border-opacity: 1;
  border-color: rgb(231 246 253 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(23 164 234 / var(--tw-bg-opacity));
}

.usp--green:before {
  --tw-border-opacity: 1;
  border-color: rgb(233 250 243 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(40 204 139 / var(--tw-bg-opacity));
}

.usp--yellow:before {
  --tw-border-opacity: 1;
  border-color: rgb(255 250 240 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 105 / var(--tw-bg-opacity));
}

.quote {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (width >= 1024px) {
  .quote {
    flex-direction: row;
  }
}

.quote:after {
  content: url("quote-balloon.cfdda6d0.svg");
  z-index: 10;
  line-height: 0;
  position: absolute;
  bottom: -.625rem;
}

.quote p {
  letter-spacing: 0;
}

@media (width >= 1024px) {
  .quote p {
    margin-top: 0;
  }
}

.quote svg {
  align-self: flex-start;
}

@media (width >= 1024px) {
  .quote svg {
    flex-shrink: 0;
    margin-right: 1.75rem;
  }
}

.menu {
  height: 120px;
  display: flex;
}

.menu .menu-item {
  justify-content: flex-end;
  height: 100%;
  display: flex;
  position: relative;
}

.menu .menu-item a {
  --tw-text-opacity: 1;
  color: rgb(127 143 164 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.menu .menu-item a.active, .menu .menu-item a:hover, .menu .menu-item a:focus {
  --tw-text-opacity: 1;
  color: rgb(25 30 60 / var(--tw-text-opacity));
  font-weight: 800;
}

.menu .menu-item a.active:after, .menu .menu-item a:hover:after, .menu .menu-item a:focus:after {
  --tw-bg-opacity: 1;
  background-color: rgb(23 164 234 / var(--tw-bg-opacity));
  content: "";
  height: .25rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.menu .menu-item a:before {
  visibility: hidden;
  color: #0000;
  content: attr(title);
  height: 0;
  font-weight: 800;
  display: block;
  overflow: hidden;
}

.menu-mobile .menu-item {
  padding-left: 4rem;
  display: flex;
  position: relative;
}

.menu-mobile .menu-item a {
  --tw-text-opacity: 1;
  color: rgb(127 143 164 / var(--tw-text-opacity));
  font-size: 30px;
  display: flex;
}

.menu-mobile .menu-item a.active, .menu-mobile .menu-item a:hover, .menu-mobile .menu-item a:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 800;
}

.menu-mobile .menu-item a.active:after, .menu-mobile .menu-item a:hover:after, .menu-mobile .menu-item a:focus:after {
  --tw-bg-opacity: 1;
  background-color: rgb(23 164 234 / var(--tw-bg-opacity));
  content: "";
  width: .25rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-bottom-2\.5 {
  bottom: -.625rem;
}

.-bottom-\[238px\] {
  bottom: -238px;
}

.-bottom-\[9px\] {
  bottom: -9px;
}

.-left-2\.5 {
  left: -.625rem;
}

.-left-\[100\%\] {
  left: -100%;
}

.-right-2\.5 {
  right: -.625rem;
}

.-right-6 {
  right: -1.5rem;
}

.-right-\[264px\] {
  right: -264px;
}

.-right-\[294px\] {
  right: -294px;
}

.-right-full {
  right: -100%;
}

.-top-11 {
  top: -2.75rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-8 {
  top: -2rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-\[43px\] {
  bottom: 43px;
}

.left-0 {
  left: 0;
}

.left-\[30px\] {
  left: 30px;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-\[-625px\] {
  right: -625px;
}

.right-\[100\%\] {
  right: 100%;
}

.right-\[38px\] {
  right: 38px;
}

.top-0 {
  top: 0;
}

.top-\[26px\] {
  top: 26px;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-60 {
  margin-top: -15rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-12 {
  height: 3rem;
}

.h-36 {
  height: 9rem;
}

.h-8 {
  height: 2rem;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[166px\] {
  height: 166px;
}

.h-\[277px\] {
  height: 277px;
}

.h-\[50px\] {
  height: 50px;
}

.h-full {
  height: 100%;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-12 {
  width: 3rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[158px\] {
  width: 158px;
}

.w-\[40vw\] {
  width: 40vw;
}

.w-\[469px\] {
  width: 469px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[863px\] {
  width: 863px;
}

.w-\[95px\] {
  width: 95px;
}

.w-full {
  width: 100%;
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.self-start {
  align-self: flex-start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[28px\] {
  border-width: 28px;
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(40 204 139 / var(--tw-border-opacity));
}

.border-grey-300 {
  --tw-border-opacity: 1;
  border-color: rgb(206 208 218 / var(--tw-border-opacity));
}

.border-grey-400 {
  --tw-border-opacity: 1;
  border-color: rgb(127 143 164 / var(--tw-border-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 246 253 / var(--tw-bg-opacity));
}

.bg-blue-100\/0 {
  background-color: #e7f6fd00;
}

.bg-blue-100\/10 {
  background-color: #e7f6fd1a;
}

.bg-blue-100\/100 {
  background-color: #e7f6fd;
}

.bg-blue-100\/15 {
  background-color: #e7f6fd26;
}

.bg-blue-100\/20 {
  background-color: #e7f6fd33;
}

.bg-blue-100\/25 {
  background-color: #e7f6fd40;
}

.bg-blue-100\/30 {
  background-color: #e7f6fd4d;
}

.bg-blue-100\/35 {
  background-color: #e7f6fd59;
}

.bg-blue-100\/40 {
  background-color: #e7f6fd66;
}

.bg-blue-100\/45 {
  background-color: #e7f6fd73;
}

.bg-blue-100\/5 {
  background-color: #e7f6fd0d;
}

.bg-blue-100\/50 {
  background-color: #e7f6fd80;
}

.bg-blue-100\/55 {
  background-color: #e7f6fd8c;
}

.bg-blue-100\/60 {
  background-color: #e7f6fd99;
}

.bg-blue-100\/65 {
  background-color: #e7f6fda6;
}

.bg-blue-100\/70 {
  background-color: #e7f6fdb3;
}

.bg-blue-100\/75 {
  background-color: #e7f6fdbf;
}

.bg-blue-100\/80 {
  background-color: #e7f6fdcc;
}

.bg-blue-100\/85 {
  background-color: #e7f6fdd9;
}

.bg-blue-100\/90 {
  background-color: #e7f6fde6;
}

.bg-blue-100\/95 {
  background-color: #e7f6fdf2;
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 164 234 / var(--tw-bg-opacity));
}

.bg-blue-300\/0 {
  background-color: #17a4ea00;
}

.bg-blue-300\/10 {
  background-color: #17a4ea1a;
}

.bg-blue-300\/100 {
  background-color: #17a4ea;
}

.bg-blue-300\/15 {
  background-color: #17a4ea26;
}

.bg-blue-300\/20 {
  background-color: #17a4ea33;
}

.bg-blue-300\/25 {
  background-color: #17a4ea40;
}

.bg-blue-300\/30 {
  background-color: #17a4ea4d;
}

.bg-blue-300\/35 {
  background-color: #17a4ea59;
}

.bg-blue-300\/40 {
  background-color: #17a4ea66;
}

.bg-blue-300\/45 {
  background-color: #17a4ea73;
}

.bg-blue-300\/5 {
  background-color: #17a4ea0d;
}

.bg-blue-300\/50 {
  background-color: #17a4ea80;
}

.bg-blue-300\/55 {
  background-color: #17a4ea8c;
}

.bg-blue-300\/60 {
  background-color: #17a4ea99;
}

.bg-blue-300\/65 {
  background-color: #17a4eaa6;
}

.bg-blue-300\/70 {
  background-color: #17a4eab3;
}

.bg-blue-300\/75 {
  background-color: #17a4eabf;
}

.bg-blue-300\/80 {
  background-color: #17a4eacc;
}

.bg-blue-300\/85 {
  background-color: #17a4ead9;
}

.bg-blue-300\/90 {
  background-color: #17a4eae6;
}

.bg-blue-300\/95 {
  background-color: #17a4eaf2;
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(61 83 114 / var(--tw-bg-opacity));
}

.bg-blue-400\/0 {
  background-color: #3d537200;
}

.bg-blue-400\/10 {
  background-color: #3d53721a;
}

.bg-blue-400\/100 {
  background-color: #3d5372;
}

.bg-blue-400\/15 {
  background-color: #3d537226;
}

.bg-blue-400\/20 {
  background-color: #3d537233;
}

.bg-blue-400\/25 {
  background-color: #3d537240;
}

.bg-blue-400\/30 {
  background-color: #3d53724d;
}

.bg-blue-400\/35 {
  background-color: #3d537259;
}

.bg-blue-400\/40 {
  background-color: #3d537266;
}

.bg-blue-400\/45 {
  background-color: #3d537273;
}

.bg-blue-400\/5 {
  background-color: #3d53720d;
}

.bg-blue-400\/50 {
  background-color: #3d537280;
}

.bg-blue-400\/55 {
  background-color: #3d53728c;
}

.bg-blue-400\/60 {
  background-color: #3d537299;
}

.bg-blue-400\/65 {
  background-color: #3d5372a6;
}

.bg-blue-400\/70 {
  background-color: #3d5372b3;
}

.bg-blue-400\/75 {
  background-color: #3d5372bf;
}

.bg-blue-400\/80 {
  background-color: #3d5372cc;
}

.bg-blue-400\/85 {
  background-color: #3d5372d9;
}

.bg-blue-400\/90 {
  background-color: #3d5372e6;
}

.bg-blue-400\/95 {
  background-color: #3d5372f2;
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(25 30 60 / var(--tw-bg-opacity));
}

.bg-blue-900\/0 {
  background-color: #191e3c00;
}

.bg-blue-900\/10 {
  background-color: #191e3c1a;
}

.bg-blue-900\/100 {
  background-color: #191e3c;
}

.bg-blue-900\/15 {
  background-color: #191e3c26;
}

.bg-blue-900\/20 {
  background-color: #191e3c33;
}

.bg-blue-900\/25 {
  background-color: #191e3c40;
}

.bg-blue-900\/30 {
  background-color: #191e3c4d;
}

.bg-blue-900\/35 {
  background-color: #191e3c59;
}

.bg-blue-900\/40 {
  background-color: #191e3c66;
}

.bg-blue-900\/45 {
  background-color: #191e3c73;
}

.bg-blue-900\/5 {
  background-color: #191e3c0d;
}

.bg-blue-900\/50 {
  background-color: #191e3c80;
}

.bg-blue-900\/55 {
  background-color: #191e3c8c;
}

.bg-blue-900\/60 {
  background-color: #191e3c99;
}

.bg-blue-900\/65 {
  background-color: #191e3ca6;
}

.bg-blue-900\/70 {
  background-color: #191e3cb3;
}

.bg-blue-900\/75 {
  background-color: #191e3cbf;
}

.bg-blue-900\/80 {
  background-color: #191e3ccc;
}

.bg-blue-900\/85 {
  background-color: #191e3cd9;
}

.bg-blue-900\/90 {
  background-color: #191e3ce6;
}

.bg-blue-900\/95 {
  background-color: #191e3cf2;
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 250 243 / var(--tw-bg-opacity));
}

.bg-green-100\/0 {
  background-color: #e9faf300;
}

.bg-green-100\/10 {
  background-color: #e9faf31a;
}

.bg-green-100\/100 {
  background-color: #e9faf3;
}

.bg-green-100\/15 {
  background-color: #e9faf326;
}

.bg-green-100\/20 {
  background-color: #e9faf333;
}

.bg-green-100\/25 {
  background-color: #e9faf340;
}

.bg-green-100\/30 {
  background-color: #e9faf34d;
}

.bg-green-100\/35 {
  background-color: #e9faf359;
}

.bg-green-100\/40 {
  background-color: #e9faf366;
}

.bg-green-100\/45 {
  background-color: #e9faf373;
}

.bg-green-100\/5 {
  background-color: #e9faf30d;
}

.bg-green-100\/50 {
  background-color: #e9faf380;
}

.bg-green-100\/55 {
  background-color: #e9faf38c;
}

.bg-green-100\/60 {
  background-color: #e9faf399;
}

.bg-green-100\/65 {
  background-color: #e9faf3a6;
}

.bg-green-100\/70 {
  background-color: #e9faf3b3;
}

.bg-green-100\/75 {
  background-color: #e9faf3bf;
}

.bg-green-100\/80 {
  background-color: #e9faf3cc;
}

.bg-green-100\/85 {
  background-color: #e9faf3d9;
}

.bg-green-100\/90 {
  background-color: #e9faf3e6;
}

.bg-green-100\/95 {
  background-color: #e9faf3f2;
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(40 204 139 / var(--tw-bg-opacity));
}

.bg-green-300\/0 {
  background-color: #28cc8b00;
}

.bg-green-300\/10 {
  background-color: #28cc8b1a;
}

.bg-green-300\/100 {
  background-color: #28cc8b;
}

.bg-green-300\/15 {
  background-color: #28cc8b26;
}

.bg-green-300\/20 {
  background-color: #28cc8b33;
}

.bg-green-300\/25 {
  background-color: #28cc8b40;
}

.bg-green-300\/30 {
  background-color: #28cc8b4d;
}

.bg-green-300\/35 {
  background-color: #28cc8b59;
}

.bg-green-300\/40 {
  background-color: #28cc8b66;
}

.bg-green-300\/45 {
  background-color: #28cc8b73;
}

.bg-green-300\/5 {
  background-color: #28cc8b0d;
}

.bg-green-300\/50 {
  background-color: #28cc8b80;
}

.bg-green-300\/55 {
  background-color: #28cc8b8c;
}

.bg-green-300\/60 {
  background-color: #28cc8b99;
}

.bg-green-300\/65 {
  background-color: #28cc8ba6;
}

.bg-green-300\/70 {
  background-color: #28cc8bb3;
}

.bg-green-300\/75 {
  background-color: #28cc8bbf;
}

.bg-green-300\/80 {
  background-color: #28cc8bcc;
}

.bg-green-300\/85 {
  background-color: #28cc8bd9;
}

.bg-green-300\/90 {
  background-color: #28cc8be6;
}

.bg-green-300\/95 {
  background-color: #28cc8bf2;
}

.bg-grey-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 247 248 / var(--tw-bg-opacity));
}

.bg-grey-100\/0 {
  background-color: #f6f7f800;
}

.bg-grey-100\/10 {
  background-color: #f6f7f81a;
}

.bg-grey-100\/100 {
  background-color: #f6f7f8;
}

.bg-grey-100\/15 {
  background-color: #f6f7f826;
}

.bg-grey-100\/20 {
  background-color: #f6f7f833;
}

.bg-grey-100\/25 {
  background-color: #f6f7f840;
}

.bg-grey-100\/30 {
  background-color: #f6f7f84d;
}

.bg-grey-100\/35 {
  background-color: #f6f7f859;
}

.bg-grey-100\/40 {
  background-color: #f6f7f866;
}

.bg-grey-100\/45 {
  background-color: #f6f7f873;
}

.bg-grey-100\/5 {
  background-color: #f6f7f80d;
}

.bg-grey-100\/50 {
  background-color: #f6f7f880;
}

.bg-grey-100\/55 {
  background-color: #f6f7f88c;
}

.bg-grey-100\/60 {
  background-color: #f6f7f899;
}

.bg-grey-100\/65 {
  background-color: #f6f7f8a6;
}

.bg-grey-100\/70 {
  background-color: #f6f7f8b3;
}

.bg-grey-100\/75 {
  background-color: #f6f7f8bf;
}

.bg-grey-100\/80 {
  background-color: #f6f7f8cc;
}

.bg-grey-100\/85 {
  background-color: #f6f7f8d9;
}

.bg-grey-100\/90 {
  background-color: #f6f7f8e6;
}

.bg-grey-100\/95 {
  background-color: #f6f7f8f2;
}

.bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 234 241 / var(--tw-bg-opacity));
}

.bg-grey-200\/0 {
  background-color: #e8eaf100;
}

.bg-grey-200\/10 {
  background-color: #e8eaf11a;
}

.bg-grey-200\/100 {
  background-color: #e8eaf1;
}

.bg-grey-200\/15 {
  background-color: #e8eaf126;
}

.bg-grey-200\/20 {
  background-color: #e8eaf133;
}

.bg-grey-200\/25 {
  background-color: #e8eaf140;
}

.bg-grey-200\/30 {
  background-color: #e8eaf14d;
}

.bg-grey-200\/35 {
  background-color: #e8eaf159;
}

.bg-grey-200\/40 {
  background-color: #e8eaf166;
}

.bg-grey-200\/45 {
  background-color: #e8eaf173;
}

.bg-grey-200\/5 {
  background-color: #e8eaf10d;
}

.bg-grey-200\/50 {
  background-color: #e8eaf180;
}

.bg-grey-200\/55 {
  background-color: #e8eaf18c;
}

.bg-grey-200\/60 {
  background-color: #e8eaf199;
}

.bg-grey-200\/65 {
  background-color: #e8eaf1a6;
}

.bg-grey-200\/70 {
  background-color: #e8eaf1b3;
}

.bg-grey-200\/75 {
  background-color: #e8eaf1bf;
}

.bg-grey-200\/80 {
  background-color: #e8eaf1cc;
}

.bg-grey-200\/85 {
  background-color: #e8eaf1d9;
}

.bg-grey-200\/90 {
  background-color: #e8eaf1e6;
}

.bg-grey-200\/95 {
  background-color: #e8eaf1f2;
}

.bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(206 208 218 / var(--tw-bg-opacity));
}

.bg-grey-300\/0 {
  background-color: #ced0da00;
}

.bg-grey-300\/10 {
  background-color: #ced0da1a;
}

.bg-grey-300\/100 {
  background-color: #ced0da;
}

.bg-grey-300\/15 {
  background-color: #ced0da26;
}

.bg-grey-300\/20 {
  background-color: #ced0da33;
}

.bg-grey-300\/25 {
  background-color: #ced0da40;
}

.bg-grey-300\/30 {
  background-color: #ced0da4d;
}

.bg-grey-300\/35 {
  background-color: #ced0da59;
}

.bg-grey-300\/40 {
  background-color: #ced0da66;
}

.bg-grey-300\/45 {
  background-color: #ced0da73;
}

.bg-grey-300\/5 {
  background-color: #ced0da0d;
}

.bg-grey-300\/50 {
  background-color: #ced0da80;
}

.bg-grey-300\/55 {
  background-color: #ced0da8c;
}

.bg-grey-300\/60 {
  background-color: #ced0da99;
}

.bg-grey-300\/65 {
  background-color: #ced0daa6;
}

.bg-grey-300\/70 {
  background-color: #ced0dab3;
}

.bg-grey-300\/75 {
  background-color: #ced0dabf;
}

.bg-grey-300\/80 {
  background-color: #ced0dacc;
}

.bg-grey-300\/85 {
  background-color: #ced0dad9;
}

.bg-grey-300\/90 {
  background-color: #ced0dae6;
}

.bg-grey-300\/95 {
  background-color: #ced0daf2;
}

.bg-grey-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(127 143 164 / var(--tw-bg-opacity));
}

.bg-grey-400\/0 {
  background-color: #7f8fa400;
}

.bg-grey-400\/10 {
  background-color: #7f8fa41a;
}

.bg-grey-400\/100 {
  background-color: #7f8fa4;
}

.bg-grey-400\/15 {
  background-color: #7f8fa426;
}

.bg-grey-400\/20 {
  background-color: #7f8fa433;
}

.bg-grey-400\/25 {
  background-color: #7f8fa440;
}

.bg-grey-400\/30 {
  background-color: #7f8fa44d;
}

.bg-grey-400\/35 {
  background-color: #7f8fa459;
}

.bg-grey-400\/40 {
  background-color: #7f8fa466;
}

.bg-grey-400\/45 {
  background-color: #7f8fa473;
}

.bg-grey-400\/5 {
  background-color: #7f8fa40d;
}

.bg-grey-400\/50 {
  background-color: #7f8fa480;
}

.bg-grey-400\/55 {
  background-color: #7f8fa48c;
}

.bg-grey-400\/60 {
  background-color: #7f8fa499;
}

.bg-grey-400\/65 {
  background-color: #7f8fa4a6;
}

.bg-grey-400\/70 {
  background-color: #7f8fa4b3;
}

.bg-grey-400\/75 {
  background-color: #7f8fa4bf;
}

.bg-grey-400\/80 {
  background-color: #7f8fa4cc;
}

.bg-grey-400\/85 {
  background-color: #7f8fa4d9;
}

.bg-grey-400\/90 {
  background-color: #7f8fa4e6;
}

.bg-grey-400\/95 {
  background-color: #7f8fa4f2;
}

.bg-grey-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 25 42 / var(--tw-bg-opacity));
}

.bg-grey-900\/0 {
  background-color: #17192a00;
}

.bg-grey-900\/10 {
  background-color: #17192a1a;
}

.bg-grey-900\/100 {
  background-color: #17192a;
}

.bg-grey-900\/15 {
  background-color: #17192a26;
}

.bg-grey-900\/20 {
  background-color: #17192a33;
}

.bg-grey-900\/25 {
  background-color: #17192a40;
}

.bg-grey-900\/30 {
  background-color: #17192a4d;
}

.bg-grey-900\/35 {
  background-color: #17192a59;
}

.bg-grey-900\/40 {
  background-color: #17192a66;
}

.bg-grey-900\/45 {
  background-color: #17192a73;
}

.bg-grey-900\/5 {
  background-color: #17192a0d;
}

.bg-grey-900\/50 {
  background-color: #17192a80;
}

.bg-grey-900\/55 {
  background-color: #17192a8c;
}

.bg-grey-900\/60 {
  background-color: #17192a99;
}

.bg-grey-900\/65 {
  background-color: #17192aa6;
}

.bg-grey-900\/70 {
  background-color: #17192ab3;
}

.bg-grey-900\/75 {
  background-color: #17192abf;
}

.bg-grey-900\/80 {
  background-color: #17192acc;
}

.bg-grey-900\/85 {
  background-color: #17192ad9;
}

.bg-grey-900\/90 {
  background-color: #17192ae6;
}

.bg-grey-900\/95 {
  background-color: #17192af2;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 53 69 / var(--tw-bg-opacity));
}

.bg-red-500\/0 {
  background-color: #dc354500;
}

.bg-red-500\/10 {
  background-color: #dc35451a;
}

.bg-red-500\/100 {
  background-color: #dc3545;
}

.bg-red-500\/15 {
  background-color: #dc354526;
}

.bg-red-500\/20 {
  background-color: #dc354533;
}

.bg-red-500\/25 {
  background-color: #dc354540;
}

.bg-red-500\/30 {
  background-color: #dc35454d;
}

.bg-red-500\/35 {
  background-color: #dc354559;
}

.bg-red-500\/40 {
  background-color: #dc354566;
}

.bg-red-500\/45 {
  background-color: #dc354573;
}

.bg-red-500\/5 {
  background-color: #dc35450d;
}

.bg-red-500\/50 {
  background-color: #dc354580;
}

.bg-red-500\/55 {
  background-color: #dc35458c;
}

.bg-red-500\/60 {
  background-color: #dc354599;
}

.bg-red-500\/65 {
  background-color: #dc3545a6;
}

.bg-red-500\/70 {
  background-color: #dc3545b3;
}

.bg-red-500\/75 {
  background-color: #dc3545bf;
}

.bg-red-500\/80 {
  background-color: #dc3545cc;
}

.bg-red-500\/85 {
  background-color: #dc3545d9;
}

.bg-red-500\/90 {
  background-color: #dc3545e6;
}

.bg-red-500\/95 {
  background-color: #dc3545f2;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 250 240 / var(--tw-bg-opacity));
}

.bg-yellow-100\/0 {
  background-color: #fffaf000;
}

.bg-yellow-100\/10 {
  background-color: #fffaf01a;
}

.bg-yellow-100\/100 {
  background-color: #fffaf0;
}

.bg-yellow-100\/15 {
  background-color: #fffaf026;
}

.bg-yellow-100\/20 {
  background-color: #fffaf033;
}

.bg-yellow-100\/25 {
  background-color: #fffaf040;
}

.bg-yellow-100\/30 {
  background-color: #fffaf04d;
}

.bg-yellow-100\/35 {
  background-color: #fffaf059;
}

.bg-yellow-100\/40 {
  background-color: #fffaf066;
}

.bg-yellow-100\/45 {
  background-color: #fffaf073;
}

.bg-yellow-100\/5 {
  background-color: #fffaf00d;
}

.bg-yellow-100\/50 {
  background-color: #fffaf080;
}

.bg-yellow-100\/55 {
  background-color: #fffaf08c;
}

.bg-yellow-100\/60 {
  background-color: #fffaf099;
}

.bg-yellow-100\/65 {
  background-color: #fffaf0a6;
}

.bg-yellow-100\/70 {
  background-color: #fffaf0b3;
}

.bg-yellow-100\/75 {
  background-color: #fffaf0bf;
}

.bg-yellow-100\/80 {
  background-color: #fffaf0cc;
}

.bg-yellow-100\/85 {
  background-color: #fffaf0d9;
}

.bg-yellow-100\/90 {
  background-color: #fffaf0e6;
}

.bg-yellow-100\/95 {
  background-color: #fffaf0f2;
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 105 / var(--tw-bg-opacity));
}

.bg-yellow-300\/0 {
  background-color: #ffcc6900;
}

.bg-yellow-300\/10 {
  background-color: #ffcc691a;
}

.bg-yellow-300\/100 {
  background-color: #ffcc69;
}

.bg-yellow-300\/15 {
  background-color: #ffcc6926;
}

.bg-yellow-300\/20 {
  background-color: #ffcc6933;
}

.bg-yellow-300\/25 {
  background-color: #ffcc6940;
}

.bg-yellow-300\/30 {
  background-color: #ffcc694d;
}

.bg-yellow-300\/35 {
  background-color: #ffcc6959;
}

.bg-yellow-300\/40 {
  background-color: #ffcc6966;
}

.bg-yellow-300\/45 {
  background-color: #ffcc6973;
}

.bg-yellow-300\/5 {
  background-color: #ffcc690d;
}

.bg-yellow-300\/50 {
  background-color: #ffcc6980;
}

.bg-yellow-300\/55 {
  background-color: #ffcc698c;
}

.bg-yellow-300\/60 {
  background-color: #ffcc6999;
}

.bg-yellow-300\/65 {
  background-color: #ffcc69a6;
}

.bg-yellow-300\/70 {
  background-color: #ffcc69b3;
}

.bg-yellow-300\/75 {
  background-color: #ffcc69bf;
}

.bg-yellow-300\/80 {
  background-color: #ffcc69cc;
}

.bg-yellow-300\/85 {
  background-color: #ffcc69d9;
}

.bg-yellow-300\/90 {
  background-color: #ffcc69e6;
}

.bg-yellow-300\/95 {
  background-color: #ffcc69f2;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.p-\[17px\] {
  padding: 17px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-\[72px\] {
  padding-left: 72px;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-60 {
  padding-top: 15rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: Greycliff CF, sans-serif;
}

.text-2xl {
  letter-spacing: -1px;
  font-size: clamp(1.875rem, 1.743rem + .5634vw, 2.25rem);
}

.text-3xl {
  letter-spacing: -1px;
  font-size: clamp(2.25rem, 2.0299rem + .939vw, 2.875rem);
  line-height: 2.75rem;
}

.text-4xl {
  letter-spacing: -.4px;
  font-size: clamp(2.5rem, 2.1479rem + 1.5023vw, 3.5rem);
  line-height: 2.75rem;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.5rem;
}

.text-xl {
  letter-spacing: -1px;
  font-size: clamp(1.125rem, 1.081rem + .1878vw, 1.25rem);
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.leading-\[0\] {
  line-height: 0;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-normal {
  letter-spacing: 0;
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(23 164 234 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(61 83 114 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(25 30 60 / var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(40 204 139 / var(--tw-text-opacity));
}

.text-grey-400 {
  --tw-text-opacity: 1;
  color: rgb(127 143 164 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-70 {
  opacity: .7;
}

.shadow-button {
  --tw-shadow: 0 10px 20px 0 #00000026;
  --tw-shadow-colored: 0 10px 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-hero-img-overlay-1 {
  --tw-shadow: 0 14px 29px 0 #0000001a;
  --tw-shadow-colored: 0 14px 29px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-hero-img-overlay-2 {
  --tw-shadow: 0 17px 35px 0 #0000001a;
  --tw-shadow-colored: 0 17px 35px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-quote {
  --tw-shadow: 0 20px 40px 0 #0003;
  --tw-shadow-colored: 0 20px 40px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-slider-arrow {
  --tw-shadow: 0 0 20px 0 #0003;
  --tw-shadow-colored: 0 0 20px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.flow > * + * {
  --flow-space-top: 1.5rem;
  --flow-space-bottom: 0;
  margin-bottom: var(--flow-space-bottom);
  margin-top: var(--flow-space-top);
}

.flow-space-y-lg {
  --flow-space-top: 2rem;
  --flow-space-bottom: 2rem;
}

@media (width >= 1024px) {
  .flow-space-y-lg {
    --flow-space-top: 4rem;
    --flow-space-bottom: 4rem;
  }
}

.wrapper {
  width: min(100% - var(--padding, 2.5rem), var(--max-width, 73.75rem));
  margin-inline: auto;
}

.wrapper-slim {
  --max-width: 48.75rem;
}

.section--dark {
  --tw-bg-opacity: 1;
  background-color: rgb(25 30 60 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(127 143 164 / var(--tw-text-opacity));
}

.section--references {
  position: relative;
}

.section--references:after {
  content: "";
  z-index: -10;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(23 164 234 / var(--tw-bg-opacity));
  border-radius: .5rem;
  width: 95px;
  height: 166px;
  display: none;
  position: absolute;
  bottom: -9px;
  left: 30px;
}

@media (width >= 1024px) {
  .section--references:after {
    width: 158px;
    height: 277px;
    display: block;
    bottom: -20px;
    left: 37px;
  }
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.after\:bg-blue-900:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(25 30 60 / var(--tw-bg-opacity));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(61 83 114 / var(--tw-text-opacity));
}

@media not all and (width >= 1024px) {
  .max-lg\:wrapper {
    width: min(100% - var(--padding, 2.5rem), var(--max-width, 73.75rem));
    margin-inline: auto;
  }
}

@media (width >= 768px) {
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:flex {
    display: flex;
  }
}

@media (width >= 1024px) {
  .lg\:relative {
    position: relative;
  }

  .lg\:-bottom-\[20px\] {
    bottom: -20px;
  }

  .lg\:-right-\[105px\] {
    right: -105px;
  }

  .lg\:-top-16 {
    top: -4rem;
  }

  .lg\:-top-\[37px\] {
    top: -37px;
  }

  .lg\:left-\[37px\] {
    left: 37px;
  }

  .lg\:right-\[-565px\] {
    right: -565px;
  }

  .lg\:z-10 {
    z-index: 10;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mb-28 {
    margin-bottom: 7rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[277px\] {
    height: 277px;
  }

  .lg\:w-\[1233px\] {
    width: 1233px;
  }

  .lg\:w-\[158px\] {
    width: 158px;
  }

  .lg\:w-\[670px\] {
    width: 670px;
  }

  .lg\:max-w-\[216px\] {
    max-width: 216px;
  }

  .lg\:max-w-\[252px\] {
    max-width: 252px;
  }

  .lg\:shrink-0 {
    flex-shrink: 0;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:text-2xl {
    letter-spacing: -1px;
    font-size: clamp(1.875rem, 1.743rem + .5634vw, 2.25rem);
  }

  .lg\:text-xl {
    letter-spacing: -1px;
    font-size: clamp(1.125rem, 1.081rem + .1878vw, 1.25rem);
  }

  .lg\:leading-\[1\.1\] {
    line-height: 1.1;
  }

  .lg\:wrapper {
    width: min(100% - var(--padding, 2.5rem), var(--max-width, 73.75rem));
    margin-inline: auto;
  }
}

@media (width >= 1280px) {
  .xl\:-left-\[50px\] {
    left: -50px;
  }

  .xl\:-right-24 {
    right: -6rem;
  }

  .xl\:bottom-\[72px\] {
    bottom: 72px;
  }

  .xl\:top-\[52px\] {
    top: 52px;
  }
}
/*# sourceMappingURL=index.447b311a.css.map */
