.usp {
  @apply pl-[72px] relative;

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M4.792 7.877 1.869 5.058.625 6.266l4.167 4.047 8.583-8.335L12.131.75z'/%3E%3C/svg%3E");
    @apply absolute w-8 h-8 p-[17px] border-[28px] rounded-full flex items-center justify-center left-0 -top-7 mt-1 -z-10;
  }

  &--blue::before {
    @apply bg-blue-300 border-blue-100;
  }

  &--green::before {
    @apply bg-green-300 border-green-100;
  }

  &--yellow::before {
    @apply bg-yellow-300 border-yellow-100;
  }
}
