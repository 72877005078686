//
// Common: Fonts
//
// @author: lemone.com
//

$fontpath: "../fonts/";

// Font definitions

@font-face {
  font-display: swap;
  font-family: "Greycliff CF";
  font-style: normal;
  font-weight: normal;
  src: url("#{$fontpath}/greycliffcf-regular.woff2") format("woff2"),
    url("#{$fontpath}/greycliffcf-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Greycliff CF";
  font-style: italic;
  font-weight: normal;
  src: url("#{$fontpath}/greycliffcf-regularoblique.woff2") format("woff2"),
    url("#{$fontpath}/greycliffcf-regularoblique.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Greycliff CF";
  font-style: normal;
  font-weight: 800;
  src: url("#{$fontpath}/greycliffcf-extrabold.woff2") format("woff2"),
    url("#{$fontpath}/greycliffcf-extrabold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Greycliff CF";
  font-style: italic;
  font-weight: 800;
  src: url("#{$fontpath}/greycliffcf-extraboldoblique.woff2") format("woff2"),
    url("#{$fontpath}/greycliffcf-extraboldoblique.woff") format("woff");
}
